.Pricing {
  height: 100%;
}



.MembershipsIntroText {
  padding-top: 75px;
  color: black;
}

.MembershipsIntroTextSubtext {
  font-size: medium;
}

.Title {
  margin-top: 1%;
}

@media screen and(max-width: 1800px) {
  .MembershipsIntroText {
    font-size: xx-large;
  }
}

@media screen and(max-width: 1200px) {
  .MembershipsIntroText {
    font-size: x-large;
  }
}
@media screen and(max-width: 900px) {
  .MembershipsIntroText {
    font-size: larger;
  }
}
@media screen and(max-width: 600px) {
  .MembershipsIntroText {
    font-size: large;
  }
}
@media screen and(max-width: 400px) {
  .MembershipsIntroText {
    font-size: large;
  }
}