.blob {
  position: relative;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  height: 100%;
  width: 100%;
  transform: scale(1);
  animation: pulse-black 2s infinite;
  background: white;
  box-shadow: 0 0 0 0 #e6510beb;
  animation: pulse 2s infinite;
}

/* @keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #42bca9;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 224, 207, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 224, 207, 0);
  }
} */

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 #eb530cfa;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 224, 207, 0);
  }

  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(52, 224, 207, 0);
  }
}
