.Modal {
  position: absolute;
  background: -webkit-linear-gradient(
    rgba(0, 0, 0, 0.834) 0%,
    rgba(0, 0, 0, 0.9) 50%,
    rgba(5, 5, 2, 0) 100%
  );
  background-color: rgba(50, 50, 50, 0.7);
  border: 2px solid black;
  border-radius: 10px;
  min-height: 70vh;
  max-height: 90vh;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 249, 243, 0.938);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.benefits-modal {
  padding: 5%;
}

.benefit-modal-title-group {
  border-bottom: 2px solid rgb(151, 151, 151);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2%;
  margin-bottom: 3%;
  padding-right: 5%;
}

.modal-close-icon {
  height: 32px;
  width: auto;
}

.benefit-modal-content {
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 10%;
  max-height: 45vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.learn-more-button-group {
  /* margin-left: auto; */
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  width: 100%;
}

.learn-more-button {
  width: max-content;
  background-color: transparent;
  border: none;
}


@media screen and (min-width: 400px) {
  .learn-more-button {
    margin-right: 25px;
  }
}

@media screen and (min-width: 1200px) {
  .Modal {
    left: 200px;
    right: 200px;
    bottom: 40px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  .benefit-modal-title {
    font-size: xx-large;
  }
  .benefit-modal-content {
    font-size: x-large;
  }
  .learn-more-button {
    margin-right: 100px;
  }
}

/* @media screen and (max-height: 1200px) {
  .Modal {
    top: 50px;
    bottom: 100px;
  }
  .benefit-modal-content {
    font-size: medium;
  }
  .learn-more-button {
    margin-right: 50px;
  }
} */

@media screen and (max-height: 2000px) {
  .Modal {
    height: fit-content;
    top: 100px;
    left: 50px;
    right: 50px;
  }
}


@media screen and (max-width: 1200px) {
  .Modal {
    height: fit-content;
    top: 20px;
    left: 50px;
    right: 50px;
    /* bottom: 40px; */
  }
  .benefit-modal-title {
    font-size: xx-large;
  }
  .benefit-modal-content {
    font-size: large;
  }
  .learn-more-button {
    margin-right: 90px;
  }
}

@media screen and (max-width: 900px) {
  .Modal {
    top: 40px;
    left: 100px;
    right: 100px;
    bottom: 40px;
  }
  .benefit-modal-content {
    font-size: larger;
  }
  .benefit-modal-title {
    font-size: x-large;
  }
  .learn-more-button {
    margin-right: 75px;
  }
}

@media screen and (max-width: 600px) {
  .Modal {
    top: 40px;
    left: 50px;
    right: 50px;
    bottom: 200px;
  }
  .benefit-modal-content {
    font-size: large;
  }
  .learn-more-button {
    margin-right: 50px;
  }
}

@media screen and (max-width: 400px) {
  .Modal {
    left: 25px;
    right: 25px;
    bottom: 200px;
  }
  .learn-more-button {
    margin-right: 50px;
  }
}


@media screen and (max-height: 800px) {
  .Modal {
    top: 50px;
    bottom: 200px;
  }
  .benefit-modal-content {
    font-size: medium;
  }
  .learn-more-button {
    margin-right: 50px;
  }
}

