


.access-button {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    border: 1px solid transparent;
    border-radius: 2px;
    height: 36px;
    line-height: 34px;
    font-size: 14px;
    color: #ffffff;
    background-color: #42bca9;
    transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    padding: 0 18px;
  }
  
  .access-button:hover {
    color: #ffffff;
    background-color: #006570;
  }

  .browser-button {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    border: 1px solid transparent;
    border-radius: 2px;
    height: 36px;
    line-height: 34px;
    font-size: 14px;
    color: #ffffff;
    /* background-color: #0d4b52; */
    background-color: #f28322;
    transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    padding: 0 18px;
  }
  
  .browser-button:hover {
    color: #f88318;
    /* background-color: #31989b; */
    background-color: #ffffff;
  }