.Book {
  /* min-height: 90vh; */
  background-color: rgba(92, 92, 92, 0.2);
}

.BookContent {
  padding-top: 50px;
  /* background-color: rgb(255, 255, 255); */
  padding-left: 5%;
  padding-right: 5%;
}


.book-note {
  margin-top: 1%;
  margin-bottom: 4%;
  margin-right: 10px;
}

.book-attention {
  background-color: yellow;
}

.havent-purchased {
  color: rgb(255, 145, 0);
  text-decoration: none;
}

.havent-purchased:hover {
  color: rgba(255, 145, 0, 0.645);
  text-decoration: none;
}

.opt-in-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  margin-left: 10%;
  margin-right: 10%;
}

.opt-in-group {
text-align: left;
}

.opt-in-title {
  margin-bottom: 100px;
}

#opt-in-checkbox {
  margin-right: 20px;
}

.ui-state-default {
  border-color: #4ae9cf!important;
}
.hc-button {
  color: #262525!important;
  background-color: #42bca9!important;
}

#hc-find-appt {
  padding-left: 90px!important;
  padding-right: 90px!important;
  color: white!important;
  background-color: #262525!important;
}

.ui-widget-header {
  background-color: #939393!important;
}

.trainer-label a {
  color: #262525!important;
}

.appointment-date-block {
  margin-bottom: 50px!important;
}

.list_view {
  z-index: 9!important;
}

.preheader {
  display: none!important;
}

footer {
  display: none!important;
}


.hc_footer {
  display: none!important;
}

.parsley-success {
  background-color: #52c4b34f!important;
}

.healcode-date-label {
  border-bottom: 3px solid #ee6423!important;
}

.mindbody-selection-prompt {
  font-size: 20px;
  margin-bottom: 20px;
}

.MindbodyChoiceSelection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.site-group {
  border: 2px solid black;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  min-width: 350px;
}

.site-group:hover {
  transform: scale(1.05);
}

.mindbody-choice-selection-img {
  height: 80px;
  margin-bottom: 20px;
}

.mindbody-choice-selection-locations {
  text-align: left;
  font-weight: 500;
  margin-left: 10px;
}

.mindbody-choice-selection-location {
  text-align: left;
  margin-left: 10px;
}