.mark-check {
  color: green;
  font-size: 1.2em;
}

.mark-x {
  color: red;
  font-weight: bold;
  font-size: 2.1em;
}

.ei-commands-title {
  font-size: x-large;
  font-weight: 800;
}

.ei-room-commands-header {
    margin-bottom: 50px;
}

.ei-room-commands-wrapper {
    display: flex;
    padding-bottom: 5%;
}

.ei-room-command-row {
  display: flex;
  /* align-content: center; */
  justify-content: space-between;
  text-align: left;
  margin-bottom: 7%;
  border: 1px solid rgba(17, 17, 17, 0.145);
  background-color: rgba(17, 17, 17, 0.145);
  border-radius: 5px;
  padding: 2%;
  /* margin-right: 5%; */
}

.ei-room-commands-group {
  background-color: grey;
  margin-right: 5%;
  padding: 2%;
  border: 1px solid black;
  border-radius: 5px;
}

.ei-room-response-status-group {
  display: flex;
  align-content: center;
}

.ei-room-response-status-wrapper {
  align-content: center;
  display: flex;
}

.ei-room-command-send {
    max-width: 150px;
    max-height: 50PX;
    margin-left: 50px;
}

.ei-room-response-group {
    padding-top: 5%;
}

/* WEB  */

/* MOBILE  */
