.ei-x-button {
  background: linear-gradient(#700, #f00);
  width: 20px;
  height: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  border-radius: 3px;
  box-shadow: 0px 1px 4px -2px #333;
  text-shadow: 0px -1px #333;
}

.ei-x-button-x {
  margin: auto;
  font-size: 10px;
  font-weight: 100;
  height: min-content;
  width: min-content;
  margin-top: 15%;
}


.ei-x-button:hover {
 transform: scale(1.2);
 cursor: pointer;
}


.ei-x-button:active {
  transform: scale(1.3);
  cursor: pointer;
 }