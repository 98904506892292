.LocationsPage::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  .LocationsPage::-webkit-scrollbar:vertical {
    width: 14px;
  }
  .LocationsPage::-webkit-scrollbar:horizontal {
    height: 14px;
  }
  .LocationsPage::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
  .LocationsPage::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }


  