.SWNavBarContainer {
  width: 100%;
  align-content: center;
  justify-content: space-around;
  z-index: 15;
  position: absolute;
  box-shadow: 0 0 10px -4px rgb(0 0 0 / 25%);
  transition: 1s ease;
}

.SWNavBarContainer-dropdown {
  background-color: transparent;
  width: 100%;
  position: sticky;
  bottom: 100px;
  align-content: center;
  justify-content: space-around;
  z-index: 15;
  box-shadow: 0 0 10px -4px rgb(0 0 0 / 25%);
  transition: 2s ease;
}

.SWNavBarContainer-dropdown-active {
  background-color: white;
  width: 100%;
  top: 0px;
  position: sticky;
  align-content: center;
  justify-content: space-around;
  z-index: 15;
  box-shadow: 0 0 10px -4px rgb(0 0 0 / 25%);
  transition: 1s ease;
}

.NavBar {
  background-color: transparent;
  display: flex;
  z-index: 100;
  justify-content: space-around;
  width: 100%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  transition: 1s ease;
}

.iconWrapper {
  width: 50px;
  height: 50px;
}

.NavLinksLeft {
  margin-left: 20px;
}
.nav-right-side {
  width: fit-content;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
}

.sweatworks-logo {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 60px;
}

.sub-nav-group {
  position: relative;
  background-color: rgba(255, 255, 255, 0.797);
}

.NavLinksRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .nav-link {
    /* margin-left: -10px; */
    /* margin-right: -10px; */
    font-size: small;
  }
}

.nav-link {
  text-shadow: 0.5px 0.5px 0 rgba(150, 150, 150, 0.4);
  letter-spacing: 0.2rem;
}

.nav-link:hover {
  color: #52c4b3d6!important;
}