.ExperienceContent {
  position: relative;
  top: -20vh;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
}

.PhoneAccessImage {
  width: auto;
  max-height: 400px;
}
.experience-content-wrapper {
  margin-left: 10%;
  margin-right: 2%;
  min-width: 70%;
  text-align: left;
  margin-bottom: 20%;
}

.TheExperienceTitle {
  position: sticky;
  top: 15%;
  padding-bottom: 2%;
  /* padding-top: 100px; */
  /* text-align: center; */
}

.the-text {
  margin-right: 10px;
}

.the-sw-experience-img {
  height: auto;
  width: 90%;
  max-width: 1200px;
}

/* .experience-title-image {
} */

.TheExperienceTitleGroup {
  display: block;
  text-align: left;
  margin-top: 5%;
  margin-bottom: 5%;
  max-width: fit-content;
  border-radius: 10px;
  margin-right: 10%;
  margin-left: 10%;
  padding: 1%;
  background-color: #262525;
}

.experience-step {
  /* min-height: 10vh; */
  margin-bottom: 20px;
  margin-right: 5%;
  max-width: 1200px;
}

.experience-step-divided {
  display: flex;
}

.experience-title {
  color: #f5680bf7;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}

.experience-step-title {
  display: flex;
  color: #f5690bf5;
  border-bottom: 3px solid #f5680bf7;
  min-width: fit-content;
  margin-bottom: 1%;
  justify-content: left;
  align-items: center;
}

.experience-step-text {
  display: inline-block;
  min-height: 15vh;
  max-width: 98%;
}

.experience-step-text-sub {
  margin-top: 5%;
  width: 100%;
  min-width: 30%;
  /* max-width: 40%; */
}
.experience-intro-wrapper {
  min-height: 20%;
}

.experience-intro-text {
  margin-right: 20px;
  margin-bottom: 10px;
}

.experience-title-image {
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  max-width: 2000px;
  /* height: auto; */
  min-height: 80vh;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.experience-step-last {
  margin-bottom: 10%;
}

.href-link {
  color: #42bca9;
}

.href-link:hover {
  color: #42bcaaa6;
}

.step-three-content-wrapper {
  display: flex;
}

.book-now-info {
  max-width: 100px;
}

.book-now-text-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 4%;
}

.book-now-text-item {
  margin-left: 5px;
  display: flex;
  justify-content: center;
  margin-right: 5px;
  min-width: max-content;
}

.book-now-text-subitem {
  margin-left: 5px;
}

.background-shader {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.9) 0%,
    #000000 100%
  );
}

.SweatDropNum {
  height: 64px;
  width: 64px;
}

.red-light-addon-iphone-wrapper {
  position: relative;
  height: 0px;
  top: 300px;
  left: 20%;
  /* height: max-content; */
  /* width: max-content; */
}

.red-light-addon-iphone {
  height: 400px;
  width: auto;
}

.experience-step-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.experience-step-left {
  min-width: 40%;
}

@media screen and (max-width: 1200px) {
  .PhoneAccessImage {
    height: 600px;
  }
  .experience-step-title-text {
    font-size: x-large;
  }
  .experience-step-text {
    font-size: larger;
  }
}

@media screen and (max-width: 900px) {
  .PhoneAccessImage {
    height: 500px;
  }
  .experience-step-title-text {
    font-size: x-large;
  }
  .experience-step-text {
    font-size: larger;
  }
}

@media screen and (max-width: 600px) {
  .PhoneAccessImage {
    height: 400px;
  }
  .experience-step-title-text {
    font-size: larger;
  }
  .experience-step-text {
    font-size: large;
  }
}

@media screen and (max-width: 500px) {
  .PhoneAccessImage {
    height: 300px;
  }
  .experience-step-title-text {
    font-size: large;
  }
  .experience-step-text {
    font-size: medium;
  }
}

@media screen and (max-width: 300px) {
  .experience-step-title-text {
    font-size: medium;
  }
  .experience-step-text {
    font-size: small;
  }
  .PhoneAccessImage {
    height: 300px;
  }
}
