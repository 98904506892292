.blog-post-wrapper {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.blog-page-content {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10%;
  text-align: left;
}

.blog-content-wrapper {
  max-width: 1200px;
  margin-left: 5%;
  margin-right: 10%;
  margin-bottom: 5%;
  white-space: pre-wrap;
}

.blog-title-image {
  padding-bottom: 10px;
  padding-left: 6%;
  background-size: cover;
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
}

.blog-header-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding-top: 4%;
  margin-bottom: 2%;
  margin-left: 5%;
  width: 100%;
}

.blog-title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding-top: 5%;
  margin-bottom: 1%;
  margin-left: 5%;
  max-width: fit-content;
  border-bottom: 3px solid black;
}

.blog-page-home-content-wrapper {
  width: 1200px;
}

.nav-tree {
  display: flex;
  align-items: center;
  margin-left: 5%;
  margin-top: 3%;
  margin-bottom: 5%;
}

.blog-not-found-wrapper {
  padding-top: 15%;
  margin-left: auto;
  margin-right: auto;
  min-height: 95vh;
  text-align: center;
}

.nav-tree-icon {
  height: 16px;
  margin-left: 1%;
  margin-right: 1%;
  width: auto;
}