.HomeTopGroup {
  position: relative;
}

.MainBackgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  background-color: transparent;
}

.VideoBackground {
  width: 100%;
}

.back-drop-video {
  background-size: cover;
  height: 100%;
}

.bg-gradient-blue-white {
  z-index: 0;
  background-image: linear-gradient(to top, #f172111c, #ffffff2d);
}

.bg-gradient-black-transparent {
  background-image: linear-gradient(to top, #000000a0, #6a6a6a00) !important;
}

.bg-gradient-transparent-black {
  background: -webkit-linear-gradient(
    rgba(82, 78, 78, 0.7) 0%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}

.bg-white {
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  /* width: 80vw;
  min-width: 800px; */
}

.bg-white-glow {
  background-color: #e6e6e631;
  box-shadow: 0px 20px 10px 10px rgba(14, 148, 148, 0.146);
}

.VideoOverlay {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 2;
  top: 75px;
  width: 90%;
  max-width: 2000px;
  height: 0px;
  margin-left: auto;
  margin-right: auto;
}

.NewClientBanner {
  background-color: black;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4%;
  padding-bottom: 5%;
  margin-top: -56px;
  z-index: 8;
}

.NewClientBannerHeadline {
  font-family: "Questrial", sans-serif;
  justify-self: center;
  color: white;
  font-size: xx-large;
  font-weight: 100;
  margin-right: 6%;
}

.NewClientBannerText {
  color: white;
  font-family: "Yaldevi", sans-serif;
  font-size: medium;
  display: flex;
}

.NewClientBannerBook {
  white-space: nowrap;
}

.NewClientBannerDollar {
  color: #42bca9;
  margin-left: 1%;
  margin-right: 1%;
}

.HomeOverlayTitleWrapper {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
}

.HomeOverlaySubtitle {
  text-align: left;
  margin-left: 20%;
  margin-bottom: 10%;
}

.HomeOverlayTitle {
  text-align: left;
  margin-left: 5%;
  margin-bottom: 20%;
}

.ColorSWBlue {
  color: #42bca9;
}

.ColorSWBlue:hover {
  color: #52c4b3d6;
}

.ColorSWOrange {
  color: #ee6423;
}

.ColorSWOrangeHov {
  color: #ee6423;
}

.ColorSWOrangeHov:hover {
  color: #ee763ed6;
}

.ColorSWRed {
  color: #cd0c0c;
}

.ColorSWRed:hover {
  color: #821111 !important;
}

.ColorWhite {
  color: white;
}

.ColorBlack {
  color: black;
}

.BackgroundColorCharcoal {
  background-color: #262525;
}

.ColorCharcoal {
  color: #262525 !important;
}

.ColorCharcoal:hover {
  color: #262525;
}

.ColorCharcoal-dark {
  color: #939393;
}

.XS {
  font-size: 10px;
}

.S {
  font-size: 13px;
}

.M {
  font-size: 16px;
}

.L {
  font-size: 18px;
}

.XL {
  font-size: 24px;
}

.XXL {
  font-size: 32px;
}

.XXXL {
  font-size: 64px;
}

.TitleFont {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  /* font-family: "Encode Sans", sans-serif; */
  text-decoration: none;
  letter-spacing: 0.1em;
}

.TitleFontSmallBold {
  font-family: "Work Sans", sans-serif;
  font-weight: 800;
  /* font-family: "Encode Sans", sans-serif; */
  text-decoration: none;
}


.ItalicContentFont {
  font-family: "Cabin", sans-serif;
}

.Left {
  text-align: left;
}

.btn-primary {
  background-color: #42bca9 !important;
  border: #42bca9 !important;
  outline: 0;
}

.btn-primary:hover {
  background-color: #42bcaaa3 !important;
  border: #4ed1be !important;
  outline: 0;
}

.btn-primary:focus {
  outline: #1f544c auto 5px;
}

.NavLink {
  font-weight: 600;
}

/* @media screen and (min-width: 400px) {
  .back-drop-video-wrapper {
    height: auto;
    width: 100vw;
    margin-top: 10px;
  }
  .back-drop-video {
    width: 100vw!important;
    height: auto;
  }
} */

.back-drop-video-group {
  background-color: rgba(34, 34, 34, 0.93);
}

@media screen and (min-width: 1275px) {
  .back-drop-video-wrapper {
    height: 700px;
  }
  .back-drop-video {
    height: 700px;
  }
}

@media screen and (max-width: 1275px) {
  .back-drop-video-wrapper {
    height: 650px;
  }
  .back-drop-video {
    height: 650px;
  }
}

@media screen and (max-width: 900px) {
  .back-drop-video {
    height: 500px;
  }
  .back-drop-video-wrapper {
    height: 500px;
  }
}

@media screen and (max-width: 500px) {
  .back-drop-video-wrapper {
    height: 300px;
  }
  .back-drop-video {
    height: 300px;
  }
}

@media screen and (max-width: 400px) {
  .back-drop-video-wrapper {
    height: 200px;
    /* width: 100%; */
  }
  .back-drop-video {
    height: 200px !important;
  }
}
