* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  width: 100%;
  /* overflow-x: hidden; */
  scroll-behavior: smooth;
}
.AppWrapper {
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  /* background-size: 140%; */
  background-position: top;
  background-repeat: no-repeat;
  /* background: rgb(31, 229, 189, 50%); */
  transition: background 1s ease;
}

.bgColor {
  height: 100%;
  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0.3) 100%,
    rgba(0, 0, 0, 0.7) 90%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.2) 95%,
    rgba(0, 0, 0, 0) 100%
  );
  transition: background 1.5s ease;
}

.Access {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  /* min-width: 100%; */
  max-width: 800px;
  background: -webkit-linear-gradient(
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.1) 50%,
    /* rgba(0, 0, 0, 0.7) 80%, */ /* rgba(0, 0, 0, 0.2) 90%, */
      /* rgba(0, 0, 0, 0.2) 95%, */ rgba(0, 0, 0, 0) 100%
  );
}

.AccessSubtext {
  margin-bottom: 5%;
  height: fit-content;
  color: white;
}

#ContentWrapper {
  overflow: scroll;
  height: calc(100vh - 60px);
  scroll-snap-points-y: repeat(calc(100vh - 60px));
  scroll-snap-type: y mandatory;
  top: 56px;
}
.AccessCell {
  scroll-snap-align: end;
  height: calc(100vh - 60px);
  padding-top: 60px;
  width: 100vw;
  background-color: rgb(0, 0, 0, 0, 0%);
}

#detailsWrapper {
  background-size: contain;
  background-repeat: no-repeat;
}
.ButtonWrapper {
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10%;
  margin-right: 10%;
}
.ButtonsWrapper {
  text-align: center;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30%;
  padding-right: 10%;
}
.ButtonIconWrapper {
  width: 46px;
  height: 46px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10%;
  vertical-align: middle;
}

.StatusIcon {
  width: 36px;
  height: 36px;
}

#SuccessIcon {
  color: green;
}

p {
  line-height: normal;
  font-family: "Montserrat", sans-serif;
  margin-top: 0%;
  margin-bottom: 0%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.TimeFrameCardWrapper {
  align-content: center;
  align-self: center;
  margin-left: 10%;
  margin-right: 10%;
  width: 90%;
  min-height: 100px;
  max-width: 800px;
}

#greeting {
  text-align: left;
  padding-left: 10%;
  color: #ed6426;
  font-weight: 400;
  font-size: xx-large;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#introduction {
  font-size: large;
  margin-top: 0%;
  margin-bottom: 0%;
}

#pressButtonText {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #43bdaa;
  font-weight: 400;
  font-size: large;
}
.WideImage {
  height: 50vh;
  width: auto;
  align-self: flex-end;
}

#accessTimeFrame {
  font-weight: 600;
}

.CardHeader {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 300;
  font-size: large;
}
.CardBody {
  height: 90%;
  background: -webkit-linear-gradient(
    rgba(256, 256, 256, 0.1) 0%,
    /* rgba(0, 0, 0, 0.1) 50%, */ rgb(0, 0, 0, 0.5) 100%
  );
  background-color: #353d3b;
}
.CardBodyText {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 200;
  font-size: medium;
  margin-top: 10%;
  margin-bottom: 10%;
}
.AccessSubtitle {
  color: #646464;
  margin-top: 2%;
  margin-bottom: 2%;
  font-style: italic;
  font-size: small;
}
.LoadingAnimationContainer {
  width: 100%;
  height: auto;
}

.MessageBox {
  background-color: #dddd00;
  color: black;
  font-weight: 400;
  width: fit-content;
  max-width: 90%;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0.5em;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  padding-left: 5%;
  padding-right: 5%;
}
.MessageBoxWrapper {
  height: auto;
  width: 100%;
  align-items: center;
  /* margin-bottom: 1em; */
}
.SweatWorksBackgroundImage {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  background-repeat: no-repeat;
  padding-top: 10%;
  padding-bottom: 5%;
}

.RedLightImage {
  width: 95%;
  height: auto;
}

.WindowPane {
  opacity: 0;
  height: 10vh;
  width: 100vw;
}
.BottomFade {
  height: 10vh;
}
.BottomFade {
  height: 10vh;
}
.AddRedLightViewWrapper {
  width: 100%;
  max-width: 800px;
  height: 30vh;
  /* background-color: black; */
}

.AddonCard {
  /* background-color: rgb(0, 0, 0, 0, 0%) !important; */
  /* color: rgb(0, 0, 0, 0, 0%) !important; */
  width: 90vw;
  height: 65vh;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

#RedLightImage {
  width: 100%;
}

#UvbLightImage {
  width: 100%;
}

#TowelsImage {
  width: 100%;
}

#UvbContentWrapper {
  height: 70vh;
  background-color: #353d3b;
}

#InvalidText {
  font-family: "Montserrat", sans-serif;
  width: 80%;
  margin-top: 20%;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  font-size: medium;
}

.StatusIconWrapper {
  color: rgb(0, 0, 0, 0, 0%);
  /* background-color: #DDDD00; */
}

.AccessContentLoadingWrapper {
  background-color: #111413;
}

.access-button {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border: 1px solid transparent;
  border-radius: 2px;
  height: 36px;
  line-height: 34px;
  font-size: 14px;
  color: #ffffff;
  background-color: #42bca9;
  transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
  padding: 0 18px;
}
.access-button:hover {
  color: #ffffff;
  background-color: #006570;
}

p {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  /* scrollbar-width: none; for Firefox */
  /* overflow-y: scroll; */
}

p::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
