.employee-interface-container {
  width: 100vw;
  height: 100vh;
  max-width: 100%; /* added */
}

.employee-interface-home-wrapper {
  text-align: left;
  padding-bottom: 20%;
  padding-top: 5%;
}

.ei-home-content-wrapper {
  margin-top: 2%;
}

.location-wrapper {
  margin-bottom: 5%;
  border: #c0c0c0 1px solid;
  background-color: #c0c0c0;
  border-radius: 5px;
  border: 1px solid black;
}
.rooms-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: 5%;
}

.room-wrapper {
  background-color: rgb(69, 69, 69);
  border-radius: 10px;
  display: flex;
  border: 1px solid black;
  margin: 2%;
  margin-bottom: 5%;
  vertical-align: middle;
}

.room-wrapper:hover {
  transition: ease 1;
  transform: scale(1.05);
  cursor: pointer;
  border-radius: 10px;
  /* -webkit-filter: blur(1px); */
}

.ei-locations-view-sauna-pic {
  max-width: 100%;
  background-position: center;
}

.ei-unlock-door-button {
  margin-right: 20px;
}

.ei-city-settings-gear {
  margin-right: 5px;
}

.ei-home-settings-group {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  padding: 4px;
  border-radius: 5px;
}

.city-name-wrapper {
  padding: 1% 0;
  background-color: black;
  display: flex;
  justify-content: space-between;
}

.ei-location-title-left-group {
  min-width: 10%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  margin-left: 2%;
}

.ei-location-title-right-group {
  display: flex;
  cursor:pointer;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  margin-right: 2%;
}

.location-title-gear {
  min-height: 32px;
  min-width: 32px;
  color: #ffffff;
  cursor: pointer;
  /* margin: 5%; */
}

.ei-city-name {
  color: white;
  margin-left: 5%;
  white-space: nowrap;
}

/* WEB  */
.sauna-picture-wrapper-web {
  padding: 5%;
  height: auto;
  width: 100px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.sauna-room-name {
  color: rgb(228, 228, 228);
  font-weight: 800;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.sauna-content-wrapper-web {
  padding-top: 5%;
  padding-left: 3%;
}

/* MOBILE  */
