.book_now_button {
  /* min-width: 50%; */
  margin-top: 5%;
  display: flex;
  width: 100%;
}

.glow-on-hover  {
  white-space: nowrap;
  width: fit-content;
  min-height: 30px;
  display: inline-block;
  font-size: medium;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  outline: none;
  color: #fff;
  background: rgba(17, 17, 17, 0.145);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 5px;
}

.glow-on-hover-disabled {
  /* width: 100%;
  cursor: arrow;
  min-height: 30px;
  font-size: small;
  padding-left: 5%;
  padding-right: 2%;
  padding-right: 5%;
  border: none;
  outline: none;
  color: #fff; */
  white-space: nowrap;
  width: fit-content;
  min-height: 30px;
  display: inline-block;
  font-size: medium;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  outline: none;
  color: #fff;
  background: rgba(106, 106, 106, 0.145, 0.145);
  position: relative;
  z-index: 0;
  border-radius: 5px;
}
.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff8401bd,
    #ff840141,
    #00ffd5,
    #0e978a,
    #0e978948,
    #a54e07a3
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 5px;
}

.glow-on-hover:active {
  color: rgba(0, 0, 0, 0.275);
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #42bca9;
  left: 0;
  top: 0;
  border-radius: 5px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
