@media (max-width: 300) {
    .sw-header-title {
        font-size: 4rem;
        z-index: 1!important;
    }
}


@media (max-width: 500) {
    .sw-header-title {
        z-index: 1!important;
        font-size: 6rem;
    }
}

@media (max-width: 1000) {
    .sw-header-title {
        z-index: 1!important;
        font-size: 10rem;
    }
}


@media (max-width: 1200) {
    .sw-header-title {
        z-index: 1!important;
        font-size: 12rem;
    }
}