.SingleSessionGroupWrapper {
  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0.3) 100%,
    rgba(0, 0, 0, 0.7) 90%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.2) 95%,
    rgba(0, 0, 0, 0) 100%
  ); 
  min-height: 90%;
}
.CardGroup {
  display: flex;
  flex-wrap: wrap;
  overflow-y: visible;
  justify-content: center;
  /* align-items: center; */
  /* margin-left: 5%; */
  /* margin-right: 5%; */
  margin-top: 5%;
}

.MembershipCard {
  margin-left: 0.5%;
  margin-right: 0.5%;
  margin-bottom: 2%;
}
.CardTitle {
  display: flex;
  justify-content: center;
}

.MembershipCard {
  width: 250px;
  border-radius: 4px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}
.single-card {
  width: 250px;
  height: 200px;
  border-radius: 4px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  margin: 2%;
}
.single-card:hover {
  transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.08);
}

.MembershipCard:hover {
  transform: scale(1.03);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.MembershipCard h1 {
  font-weight: 600;
}


.single-card {
  height: 10%;
  max-height: 300px;
  max-width: 400px;
  min-height: 400px;
  min-width: 300px;
}
.healcode-link {
  color: #42bca9;
  text-decoration: none;
}
.healcode-link :hover {
  color: #42bca9 !important;
}


