.BenefitsContentWrapper {
  /* display: flex; */
  justify-content: space-evenly;
  /* overflow-wrap: normal; */
  /* max-height: 800px; */
  background: -webkit-linear-gradient(
    rgba(0, 0, 0, 0.01) 0%,
    rgba(0, 0, 0, 0.05) 50%,
    rgba(0, 0, 0, 0.07) 90%,
    rgba(0, 0, 0, 0.3) 100%
  );
  background-color: white;
}

.BenefitsIntroductionWrapper {
  min-height: 30%;
}

.BenefitsCellIntroductionWrapper {
  margin-left: 30%;
  margin-top: 5%;
}

.BenefitsWhyWrapper {
  display: flex;
}

.BenefitsTitle {
  /* margin-right: 50%; */
  margin-top: 2%;
  /* max-width: 30px; */
}

.home-intro {
  position: relative;
  align-content: center;
  width: 100%;
  /* max-width: 1800px; */
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  z-index: 10;
}

.BenefitsCellIntroWrapper {
  display: flex;
  flex-direction: column;
  background-color: rgba(34, 34, 34, 0.93);
  /* padding: 3%; */
  /* margin-top: 1%; */
  padding-bottom: 1%;
  margin-left: auto;
  margin-right: auto;
  /* max-width: 1600px; */
  z-index: 5;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.BenefitsIntro {
  /* z-index: 5; */
  background-color: #000;
  /* background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0.7) 0%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0) 100%
  ); */
}

.bg-orange-overlay {
  background-size: cover;
  background-position: center;
}

.home-intro-container {
  min-width: 400px;
  max-width: 1400px;
  padding-left: 40px;
  padding-right: 25px;
  padding-top: 5%;
}

.WhyInfraredHow {
  text-align: left;
  border-radius: 20px;
  padding: 5%;
}

.ray-animations-wrapper {
  position: relative;
  z-index: 6;
  top: 11%;
  left: 43%;
  max-width: 50px;
}

@keyframes RayAnimation {
  0% {
    stroke-dashoffset: 0px;
    transform: translateX(119px);
  }
  100% {
    stroke-dashoffset: -136px;
    transform: translateX(0);
  }
}

.infrared-logo {
  z-index: 5;
  position: relative;
  z-index: 7;
  border-radius: 50%;
  box-shadow: 0 0 60px 50px #fff, 0 0 100px 60px rgb(201, 75, 7),
    0 0 140px 90px rgb(13, 220, 206);
}

.BenefitsBackground {
  height: 50%;
  width: 100%;
}

.BenefitsRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.BenefitCircle {
  background-color: rgb(2, 0, 2, 0);
  transition: background ease 5s;
}

.BenefitCircle:hover {
  transform: scale(1.1);
  background-color: rgb(2, 0, 2, 0.05);
  transition: background ease 5s;
  border-radius: 50%;
}

.BenefitsListTitle {
  border-bottom: 2px solid #000;
  padding-bottom: 3px;
  margin-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.unlock-healing-power-part {
  margin-right: 2%;
  width: fit-content;
  min-width: max-content;
}

.BenefitText {
  font-family: "Raleway", sans-serif;
}

.ClearlightImage {
  /* max-width:900px; */
  /* max-height:600px; */
  object-fit: contain;
  height: 40%;
  max-height: 500px;
  width: auto;
  padding-left: 90px;
  padding-right: 30px;
}

.BenefitsWhyWrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: rgba(38, 38, 38, 0.741);
}

.DifferentFromWrapper {
  justify-items: flex-start;
  margin-left: 5%;
  padding-left: 0.5%;
  padding-top: 0.5%;
  padding-right: 0.5%;
  padding-bottom: 0.5%;
  background-color: rgba(46, 46, 46, 0.778);
}

.bgDarkener {
  padding: 1%;
  background-color: rgba(31, 31, 31, 0.57);
}

.BenefitContent {
  /* max-width: 40%; */
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 10%; */
}

.onyx-benefits-grid-bg-woman-wrapper {
  /* padding-top: 100px; */
  height: 0px;
}

.onyx-benefits-grid-bg-woman {
  /* z-index: 4 !important; */
  /* position: relative; */
  height: 100%;
  max-height: 600px;
  background-size: contain;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: bottom;
  transform: translateY(-100%);
}

.onyx-benefits-grid-shader-none {
  transition: 0.5s ease !important;
}

.onyx-benefits-grid-shader-red {
  transition: 1s ease !important;
}

.BenefitsGridOverlay {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  bottom: 100px;
  justify-content: space-between;
  width: 100%;
  max-width: 625px;
  min-height: 400px;
  z-index: 5;
  display: flex;
}

.mobile-benefit-title {
  text-align: center;
  left: 0px;
  position: relative;
  right: 100%;
  width: 132px;
}

.BenefitTitle {
  text-align: center;
  min-height: 48px;
  width: min-content;
  transform: translateY(25%);
}

.onyx-benefits-grid {
  width: 100%;
  min-height: 500px;
}

.benefit-grid-overlay-wrapper {
  /* display: flex; */
  /* flex-direction: row; */
  z-index: 9;
  background-color: transparent;
  justify-content: bottom;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: auto;
  margin-right: auto;
  /* max-height: 1000px; */
  min-height: max-content;
  min-width: 400px;
}
.BenefitsIntroText {
  margin-bottom: 5%;
  max-width: 800px;
  text-align: left;
  /* margin-right: auto; */
  /* padding: 2%; */
}

.full-benefit-title {
  text-align: left;
  margin-left: 2%;
  margin-bottom: 5%;
}

.full-benefit-content {
  text-align: left;
}

.full-benefit-footnote {
  text-align: right;
  padding-right: 10%;
  padding-top: 5%;
  display: flex;
  flex-direction: row-reverse;
}
.full-benefit-footnote-text {
  width: fit-content;
  padding-left: 2%;
  padding-right: 2%;
}

#LabelGroupRight {
  transform: translateY(-10px);
  flex-direction: row;
  text-align: start;
}
#LabelGroupLeft {
  transform: translateY(-10px);
  text-align: end;
}

.benefits-grid-title-wrapper {
  /* font-size: medium; */
  padding-top: 30px;
  margin-bottom: 100px;
  text-overflow: clip;
}

.BenefitsWhyUse {
  display: flex;
  margin-bottom: 2%;
  margin-top: 3%;
  text-align: left;
}

.unlock-healing-power-group {
  margin-right: 4%;
  text-align: left;
  display: flex;
}

.BenefitsGridOverlayOnyx {
  position: relative;
  justify-content: space-between;
  /* height: 100%; */
  width: 100%;
  max-width: 625px;
  margin-left: auto;
  margin-right: auto;
  max-height: 400px;
  min-height: 400px;
  z-index: 5;
  display: flex;
}

.heater-panel-right {
  transform: scale(0.25);
}
.heater-panel-left {
  transform: scaleX(-1) scale(0.25);
}

.BenefitWrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.MobileBenefitWrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.MobileBenefitsWrapper {
  cursor: pointer;
  height: 0px;
}

.onyx-mobile-benefits-group {
  display: flex;
  justify-content: space-between;
  width: 100vw;
}

.onyx-benefits-group-left {
  top: -200px;
}

#BenefitWrapperRight {
  flex-direction: row;
}

#BenefitWrapperLeft {
  flex-direction: row-reverse;
}

.LabelGroup {
  /* position: absolute; */
  display: flex;
  /* min-width: 20%; */
  flex-direction: row-reverse;
  z-index: 4;
}

.BenefitUnderline {
  border-bottom: 1px solid #eb530cfa;
  min-width: 25px;
  width: 5vw;
  max-width: 100px;
  height: 35px;
  width: 10vw;
  z-index: 4;
}

.onyx-benefits-grid-underline {
  border-bottom: 1px solid #eb530cfa;
  min-width: 150px;
  max-width: 300px;
  height: 44px;
  width: 2vw;
  z-index: 4;
}

.BenefitIcon {
  width: 72px;
  height: 72px;
  cursor: pointer;
  /* margin: 7px; */
  z-index: 5 !important;
  justify-self: center;
}

.MobileBenefitIcon {
  margin: 2px;
  background-color: white;
  z-index: 10 !important;
  justify-self: center;
}

.blog-not-found-wrapper {
  min-height: 50vh;
}

.BenefitListWrapper {
  width: 40%;
  min-width: 400px;
  margin-top: 2%;
  /* padding: 5%; */
}

.RedLightPanel {
  margin-top: 100px;
  margin-right: 30%;
  margin-left: 30%;
}

.BenefitsIntroduction {
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-color: black;
}

.benefit-grid {
  position: relative;
}

.onyx-red-light-bg-group {
  position: relative;
  top: 200px;
  max-height: 0px;
  display: flex;
  z-index: 4;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1400px;
  justify-content: center;
}

.onyx-red-light-bg {
  height: 200px;
  width: auto;
  max-height: 600px;
}

.onyx-red-light-bg-left {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  margin-left: 1%;
  margin-right: 500px;
  max-height: 40em;
}

.onyx-red-light-bg-right {
  margin-right: 1%;
  padding-left: 50%;
  max-height: 40em;
}

.rodal-close {
  color: white !important;
}

.benefits-grid-title {
  display: inline;
  width: fit-content;
  font-weight: 800;
  color: #000;
}

.benefit-blog-anchor {
  width: fit-content;
  height: fit-content;
  text-decoration: none;
}

.benefit-blog-anchor:hover {
  width: fit-content;
  height: fit-content;
  text-decoration: none;
}

.onyx-content-wrapper {
  background-color: #000;
  min-height: 100%;
}

.FullBenefitWrapper {
  min-height: 100vh;
  max-height: 1700px;
}

.benefit-bg-image {
  height: 100vh;
  background-position: center;
  background-size: cover;
}

.BenefitContentWrapper {
  margin-left: 8%;
  margin-right: 10%;
  padding-top: 10%;
  margin-bottom: 4%;
}

.benefit-content-group {
  border: 2px solid black;
  border-radius: 5px;
  background-color: rgba(61, 56, 56, 0.76);
  padding: 20px;
  padding-bottom: 50px;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
}

.FullBenefitIconWrapper {
  display: flex;
  align-items: center;
}

.onyx-benefit-wrapper {
  z-index: 4;
}

.onyx-icon-pointer-group {
  z-index: 3;
}

@media screen and (min-width: 400px) {
  .infrared-logo {
    height: 128px;
    width: 128px;
    margin: 100px;
    z-index: 5;
  }
  .onyx-benefits-grid-bg-woman {
    height: 600px;
  }
  .MobileBenefitIcon {
    width: 46px;
    height: 46px;
  }
  .benefits-grid-title-wrapper {
    font-size: large;
  }
}

@media screen and (max-width: 2000px) {
  .full-benefit-title {
    font-size: 100px;
  }

  .full-benefit-title {
    font-size: x-large;
  }
}

@media screen and (min-width: 1200px) {
  .BenefitTitle {
    min-width: 180px;
    font-size: x-large;
  }
  .benefits-grid-title {
    font-size: xx-large;
  }
  .BenefitsWhyUse {
    font-size: xx-large;
    margin-bottom: 50px;
  }
  .benefit-grid-overlay-wrapper {
    min-width: 300px;
    height: 600px;
  }
  .BenefitsIntroText {
    margin-bottom: 3%;
    font-size: x-large;
  }
  .full-benefit-title {
    font-size: x-large;
  }
  .full-benefit-content {
    font-size: x-large;
  }
  .full-benefit-footnote {
    font-size: x-large;
  }
  .onyx-benefits-grid-bg-woman {
    height: 600px;
  }
  .unlock-healing-power-group {
    margin-right: 3%;
  }
}

@media screen and (max-width: 1200px) {
  .BenefitsGridOverlay {
    width: 300px;
  }
  .BenefitsWhyUse {
    font-size: x-large;
    margin-bottom: 50px;
  }
  .BenefitsIntroText {
    margin-bottom: 3%;
    font-size: large;
  }
  .benefit-grid-overlay-wrapper {
    min-width: 300px;
    height: 600px;
  }
  .full-benefit-title {
    font-size: x-large;
  }
  .benefits-grid-title {
    font-size: xx-large;
  }
  .what-our-clients-are-saying {
    font-size: x-large;
  }
  .full-benefit-content {
    font-size: large;
  }
  .unlock-healing-power-group {
    margin-right: 3%;
  }
}

@media screen and (max-width: 1000px) {
  /* .onyx-benefits-grid-bg {
    z-index: 9;
    margin-top: auto;
    margin-bottom: auto;
    max-height: 600px;
    min-width: 600px;
  } */
  .full-benefit-title {
    font-size: xx-large;
  }
  .full-benefit-title {
    font-size: large;
  }
  .full-benefit-content {
    font-size: large;
  }
  .unlock-healing-power-group {
    margin-right: 3%;
  }
  .what-our-clients-are-saying {
    font-size: x-large;
  }
  .benefits-grid-title {
    font-size: x-large;
  }
}
@media screen and (min-width: 900px) {
  .BenefitTitle {
    font-size: larger;
    min-width: 200px;
  }
  .BenefitsWhyUse {
    font-size: x-large;
    margin-bottom: 50px;
  }
  .benefits-grid-title {
    font-size: x-large;
  }
}

@media screen and (max-width: 600px) {
  .BenefitsGridOverlay {
    top: -200px;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
  }
  .BenefitsWhyUse {
    font-size: large;
    margin-bottom: 50px;
  }
  .BenefitsIntroText {
    margin-bottom: 3%;
    font-size: medium;
  }
  .benefit-grid-overlay-wrapper {
    min-width: 300px;
    height: 500px;
  }
  .BenefitTitle {
    font-size: larger;
  }
  .MobileBenefitIcon {
    margin: auto;
    width: 46px !important;
    height: 46px !important;
  }
  .benefits-grid-title-wrapper {
    font-size: medium;
  }
  .BenefitsWhyUse {
    font-size: large;
    margin-bottom: 50px;
  }
  .what-our-clients-are-saying {
    font-size: large;
  }
  .benefits-grid-title {
    font-size: large;
  }
}

@media screen and (max-width: 500px) {
  .onyx-benefits-grid-bg {
    z-index: 9;
    margin-top: auto;
    margin-bottom: auto;
    /* max-height: 400px; */
    max-width: 100vw;
    min-width: 100vw;
  }
  .BenefitsIntroText {
    margin-bottom: 3%;
    font-size: small;
  }
  .benefit-grid-overlay-wrapper {
    height: 500px;
  }
  .BenefitTitle {
    font-size: large;
  }
  .full-benefit-title {
    font-size: medium;
  }
  .full-benefit-content {
    font-size: medium;
  }
  .MobileBenefitIcon {
    margin: auto;
    width: 46px !important;
    height: 46px !important;
  }
  .BenefitsWhyUse {
    font-size: medium;
    margin-bottom: 10px;
    margin-top: 30px;
  }
  .what-our-clients-are-saying {
    font-size: medium;
  }
  .benefits-grid-title-wrapper {
    font-size: medium;
  }
  .benefits-grid-title {
    font-size: large;
  }
}
@media screen and (max-width: 399px) {
  .infrared-logo {
    height: 64px;
    width: 64px;
    margin: 50px;
    z-index: 5;
    /* background: transparent; */
  }
  .ir-logo-ray-group {
    transform: scale(0.5) translate(-25px, 30px);
  }
  .BenefitTitle {
    font-size: small;
  }
  .onyx-benefits-grid-bg-woman {
    background-size: cover;
    height: 400px;
  }
  .MobileBenefitIcon {
    width: 46px !important;
    height: 46px !important;
  }
  .BenefitsWhyUse {
    display: flex;
    justify-content: center;
    font-size: small;
    margin-bottom: 20px;
    margin-top: 30px;
  }
  .what-our-clients-are-saying {
    font-size: medium;
  }
  .benefits-grid-title-wrapper {
    font-size: medium;
  }
}
