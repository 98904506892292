.ei-room-view-appointments {
  background-color: rgba(17, 17, 17, 0.145);
  border: 1px solid black;
  border-radius: 5px;
  text-align: center;
  text-align: left;
}

.ei-room-view-appointment-row {
  display: flex;
  background-color: grey;
  border: 1px solid black;
  border-radius: 10px;
  justify-content: space-between;
  margin: 1px;
}

.ei-appointment-row-title {
  display: inline;
  width: fit-content;
  min-width: fit-content;
}

.ei-appointment-time-wrapper {
  display: flex;
  min-width: fit-content;
  flex-wrap: nowrap;
}

.ei-appointment-time-data {
  /* min-width: 75px; */
  padding-right: 5%;
  display: flex;
  flex-wrap: nowrap;
  display: inline-block;
  min-width: fit-content;
}

.ei-appointment-name {
  margin-left: 10%;
}

.ei-appointment-wrapper {
  display: flex;
  justify-content: space-between;
  min-width: 50%;
}

.ei-credential-content {
  display: flex;
  justify-content: space-between;
}

.ei-credential-wrapper {
  min-width: 30%;
  margin-right: 10%;
}

.ei-appointment-row-content {
  min-width: 110px;
}

.ei-appointment-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .appointments-sent-text-black {
    color: black;
} */

.ei-detail-blue {
  color: #42bca9;
  font-size: larger;
  font-weight: 800;
}

.ei-detail-orange {
  color: #7a2b07;
  font-size: larger;
  font-weight: 800;
}

.ei-credential-title {
  display: flex;
  align-items: center;
}

.ei-credential-title-text {
  margin-left: 10%;
}
