.ray-clusters-wrapper {
  display: block;
  /* padding-top: 7em; */
}

.red-light-panel-group-left {
  display: flex;
  margin-left: -35%;
  max-height: 40em;
}

.onyx-red-light-bg {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1) scale(1.4);
}

.red-light-panel-group-right {
  display: flex;
  margin-right: -35%;
  max-height: 40em;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.red-light-panel-ray-group-left {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  transform: rotate(90deg) translateY(-3em);
}

.red-light-panel-ray-group-right {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  transform: rotate(90deg) translateY(-3em);
}

.long-ray-left {
  margin-top: 5em;
}

.long-ray-cluster {
  display: flex;
}

.long-ray-cluster-open {
  /* transform: scaleX(1); */
  transition: 1s ease;
  color: transparent;
}

.long-ray-cluster-closed {
  transform: scaleX(0);
  transition: 0.5s ease;
  display: hidden;
}

.ray-cluster-wrapper {
  height: 5em;
}

.ray-cluster-wrapper-left {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  height: 5em;
}

.onyx-benefit-wrapper {
  display: block;
  z-index: 5;
}

.onyx-benefit-icon {
  width: 72px;
  height: 72px;
  margin: 7px;
  z-index: 5;
  justify-self: center;
  z-index: 100;
}

.mobile-onyx-benefit-icon {
  width: 64px;
  height: 64px;
  margin: 5px;
  z-index: 5;
  justify-self: center;
  z-index: 100;
}

.ray-wrapper {
  margin-top: 5px;
}

.onyx-pointer-first {
  position: absolute;
}

.onyx-pointer-last {
  position: absolute;
}

.mobile-onyx-icon-hover-pop-out-left {
  position: absolute;
  top: 20px;
  z-index: 15;
  /* top: 15px; */
  right: 20px;
  min-height: 40px;
  background-color: white;
  line-height: 40px;
  width: 0px;
  border-radius: 20px;
  transition: 1s ease;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.onyx-icon-hover-pop-out-left {
  position: absolute;
  transform: translateY(50%);
  z-index: 15;
  /* top: 15px; */
  right: 100px;
  min-height: 40px;
  background-color: white;
  line-height: 40px;
  width: 0px;
  border-radius: 20px;
  transition: 1s ease;
  padding-left: 10%;
  padding-right: 10%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.onyx-icon-hover-pop-out-right {
  justify-self: center;
  align-self: center;
  position: absolute;
  left: 100px;
  background-color: white;
  width: 0px;
  min-height: 40px;
  line-height: 40px;
  border-radius: 20px;
  transition: 1s ease;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.mobile-onyx-icon-hover-pop-out-right {
  justify-self: center;
  align-self: center;
  position: absolute;
  left: 100px;
  background-color: white;
  width: 0px;
  min-height: 40px;
  line-height: 40px;
  border-radius: 20px;
  transition: 1s ease;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

#onyx-icon-hovering-active {
  min-width: 200px;
}

.onyx-website-text-wrapper {
  position: relative;
  left: 50%;
  height: 0px;
}

.sw-red-light-wide-img {
  width: 100vw;
  max-width: 1200px;
  /* min-height: 200px; */
  height: auto;
}

.sw-red-light-wide-img-wrapper {
  /* height: max-content; */
  background-color: #000;
}

.onyx-website-text {
  color: white;
  max-width: 400px;
  height: 20px;
}

.wide-red-light-img {
  min-height: 100px;
  background-size: contain;
}

@media screen and (min-width: 1275px) {
  .back-drop-video-wrapper {
    height: 700px;
  }
  .back-drop-video {
    height: 700px;
  }
  .onyx-website-text-wrapper {
    top: 175px;
  }
}

@media screen and (max-width: 1275px) {
  .back-drop-video-wrapper {
    height: 650px;
  }
  .back-drop-video {
    height: 650px;
  }
  .onyx-website-text-wrapper {
    top: 150px;
  }
}

@media screen and (max-width: 900px) {
  .back-drop-video {
    height: 500px;
  }
  .back-drop-video-wrapper {
    height: 500px;
  }
  .onyx-website-text-wrapper {
    top: 125px;
  }
}

@media screen and (max-width: 500px) {
  .back-drop-video-wrapper {
    height: 300px;
  }
  .back-drop-video {
    height: 300px;
  }
  .onyx-website-text-wrapper {
    top: 100px;
  }
}

@media screen and (max-width: 400px) {
  .back-drop-video-wrapper {
    height: 200px;
    /* width: 100%; */
  }
  .back-drop-video {
    height: 200px !important;
  }
  .onyx-website-text-wrapper {
    top: 25px;
    left: 40%;
    max-width: 200px;
  }
}
