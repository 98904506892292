
.led-box {
    height: 30px;
    height: 30px;
    padding: 5px;
    /* padding-left: 10px; */
    /* padding-right: 10px; */
    float: left;
  }
/* 
  .led-box p {
    font-size: 12px;
    text-align: center;
    margin: 1em;
  } */

  .led-red {
    margin: 0 auto;
    width: 16px;
    height: 16px;
    background-color: #F00;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
    -webkit-animation: blinkRed 0.5s infinite;
    -moz-animation: blinkRed 0.5s infinite;
    -ms-animation: blinkRed 0.5s infinite;
    -o-animation: blinkRed 0.5s infinite;
    animation: blinkRed 0.5s infinite;
  }

  @-webkit-keyframes blinkRed {
      from { background-color: #F00; }
      50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
      to { background-color: #F00; }
  }
  @-moz-keyframes blinkRed {
      from { background-color: #F00; }
      50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
      to { background-color: #F00; }
  }
  @-ms-keyframes blinkRed {
      from { background-color: #F00; }
      50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
      to { background-color: #F00; }
  }
  @-o-keyframes blinkRed {
      from { background-color: #F00; }
      50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
      to { background-color: #F00; }
  }
  @keyframes blinkRed {
      from { background-color: #F00; }
      50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
      to { background-color: #F00; }
  }

.led-green {
    margin: 0 auto;
    width: 16px;
    height: 16px;
    background-color: #ABFF00;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;
  }

  .led-grey {
    margin: 0 auto;
    width: 16px;
    height: 16px;
    background-color: #464646;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #979797 0 -1px 9px, #1d1d1d 0 2px 12px;
  }