.admin-login-wrapper {
  height: 100%;
}

.ei-margins {
  max-width: 1000px;
}

.ei-sensitive-input {
  -webkit-text-security: disc;
}

.admin-login-container {
  background-color: #747e80;
  border: solid black 1px;
  margin-left: auto;
  margin-right: auto;
  min-width: 300px;
  min-height: 200px;
  width: 25%;
  padding: 2%;
  border-radius: 10px;
}

.login-input-row {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  margin-bottom: 5%;
}

.ei-signup-response-bar {
  min-height: 40px;
}

.admin-login-title {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.ei-login-row-title {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: small;
}

.login-input-wrapper {
  min-width: fit-content;
  padding-top: 10%;
}

.ei-login-button-row {
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  justify-content: space-between;
}

.ei-login-button {
  max-width: 20%;
  min-width: fit-content;
}

.sweatworks-banner-pic {
  height: auto;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}