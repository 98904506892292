.ei-room-update-button {
  max-width: 150px;
  max-height: 40px;
  /* margin: 5%; */
}

.room-header-row {
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  align-items: center;
  border-bottom: 1px solid black;
}

.si-room-automations-title-group {
  display: flex;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 1%;
  margin-left: 5%;
}

.si-room-temp-input-group {
  display: flex;
  margin-bottom: 10px;
}

.ei-room-view-sauna-pic {
  margin-top: 10px;
  margin-bottom: 10px;
  width: auto;
  max-height: 260px;
}

.ei-room-status-wrapper {
  min-width: 100px;
}

.ei-room-statuses-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.ei-room-automations-button-wrapper {
  display: flex;
}

.ei-room-automations-enabling-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 5%;
}

.ei-room-automations-button-group {
  display: flex;
}

.ei-room-automations-disabled {
  min-width: 100px;
}

.ei-room-status-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ei-room-status-green {
  color: #23e445;
}

.ei-status-online-wrapper {
  display: flex;
  align-items: center;
}

.ei-room-status-online-title {
  font-weight: 900;
  margin-right: 5px;
}

.ei-room-status-online-status {
  font-weight: 900;
  font-size: x-large;
}

.ei-room-status-online {
  color: #23e445;
}

.ei-room-status-offline {
  color: #ef1703;
}

.ei-room-status-group {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 250px;
  min-width: 25%;
  padding-left: 2%;
  padding-right: 2%;
}

.ei-room-statuses-title {
  border-bottom: 3px solid grey;
}

.ei-room-automations-title {
  font-size: large;
  text-align: left;
  margin-left: 2%;
}

.ei-room-automations-button-title {
  width: 70px;
  text-align: left;
}

.ei-room-automations-subtitle {
  font-size: small;
  margin-top: 2%;
  margin-bottom: 2%;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  margin-left: 5%;
  margin-right: 5%;
  text-align: left;
}

/* WEB  */
.room-sauna-picture-wrapper-web {
  height: 300px;
  width: auto;
  align-self: center;
  display: flex;
  align-content: center;
}

.ei-room-automations-group-web {
  min-width: 400px;
  border: 1px solid;
  /* border-image: linear-gradient(to bottom right, #42bca9, white) 1; */
  border-image-slice: 1; /* This value should match the border width */
  border-radius: 6px;
  margin-bottom: 1%;
  margin-top: 1%;
  background: -webkit-linear-gradient(
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.1) 50%,
    /* rgba(0, 0, 0, 0.7) 80%, */ /* rgba(0, 0, 0, 0.2) 90%, */ /* rgba(0, 0, 0, 0.2) 95%, */ rgba(0, 0, 0, 0) 100%
  );
}

.si-temp-goal-input {
  width: 50px;
  height: 24px;
  background-color: transparent;
  color: white;
  border-radius: 4px;
  text-align: center;
}

.ei-room-automations-button-wrapper {
  display: flex;
  margin-bottom: 5%;
  justify-content: space-between;
}

.ei-room-name {
  font-size: x-large;
}

.si-room-temp-goal-input-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: min-content;
}

.ei-room-automations-enabling-group {
  width: 50%;
}

.si-temp-goal-description {
  width: max-content;
  max-width: 250px;
  text-align: left;
}

/* MOBILE  */
