.LocationsMapWrapper {
  min-height: 90vh;
}

.Locations {
  display: flex;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

.LocationsMobile {
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

#LocationMapFull {
  max-width: 800px;
  animation: reveal 1s forwards;
  margin-left: auto;
  margin-right: auto;
}

@keyframes reveal {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.FullLocationsList {
  width: 100%;
  max-width: 600px;
  margin-left: 10%;
  min-height: 40vh;
}

.FullLocationsTitle {
  text-align: left;
  margin-top: 5%;
  margin-left: 5%;
}

.locations-side-menu {
  height: fit-content;
  width: 50%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.locations-side-menu-mobile {
  width: 100%;
  margin-bottom: 5%;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
}

.location-address-group {
  display: flex;
  justify-content: space-between;
  margin-right: 10%;
  margin-bottom: 4%;
}
