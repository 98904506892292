.a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}
a[title="Report problems with Street View imagery to Google"] .gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

a {
  text-decoration: none;
  color: black;
}

#LocationMapWidget {
  animation: reveal 3s forwards;
}

.HomeLocationRow {
  display: flex;
  justify-content: center;
  background-color: rgba(61, 56, 56, 0.1);
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.home-location-widget-background {
  position: relative;
  top: 100px;
  width: 100vw;
  max-width: 1400px;
}

.HomeLocationWidget {
  /* position: relative; */
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 2;
  max-width: 300px;
  /* background-color: white; */
  border-radius: 5px;
  background: -webkit-linear-gradient(
    rgba(0, 0, 0, 0.01) 0%,
    rgba(0, 0, 0, 0.05) 50%,
    rgba(0, 0, 0, 0.07) 90%,
    rgba(0, 0, 0, 0.3) 100%
  );
  background-color: rgba(255, 255, 255, 0.631);
}

.home-location-widget-wrapper {
  margin-top: 100px;
  display: flex;
  flex-direction: row-reverse;
  min-width: fit-content;
  /* padding-left: 100px; */
  /* padding-right: 50px; */
  position: relative;
  top: 100px;
  /* left: 5%; */
}

.location-card-title {
  text-decoration: underline;
  margin-left: 15px;
  margin-bottom: 2%;
  text-transform: uppercase;
}

.loading-modal-open {
  margin-top: 20%;
  height: 100%;
  width: 100%;
}

.loading-modal-closed {
  height: 0px;
  display: none;
}

.zip-search-container {
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
}

.search-location-input {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.sw-search-input {
  -webkit-appearance: none;
  background-color: transparent;
  height: 31px;
  width: 100%;
  margin-right: 10px;
  padding: 3px 7px;
  line-height: normal;
  border: 1px solid #a6a6a6;
  border-top-color: #949494;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgb(255 255 255 / 50%), 0 1px 0 rgb(0 0 0 / 7%) inset;
  outline: 0;
  color: rgb(255, 255, 255);
  font-size: 13px;
}

.sw-search-input:focus {
  border-color: #e77600;
  box-shadow: 0 0 3px 2px rgb(228 121 17 / 50%);
}
.sw-search-icon {
  transform: scale(0.8);
  margin-left: 2%;
  margin-right: 10px;
  cursor: pointer;
}

.sw-failed-modal-wrapper {
  height: 100%;
}

.LocationsList {
  /* animation: reveal 3s forwards; */
  z-index: 8;
  border-radius: 5px;
  /* background-color: rgba(61, 56, 56, 0.453); */
  padding: 2%;
  width: 100%;
  max-width: 300px;
  min-width: 300px;
  min-height: 300px;
}

.map-widget-title-group {
  /* border: 1px solid black; */
  border-radius: 5px;
  background-color: white;
}

.LocationMapWidget {
  background-color: rgb(1, 1, 1, 0.4);
  padding: 0.5%;
}

.LocationCardWidget {
  width: 100%;
  background-color: rgb(255, 255, 255);
}

.LocationCard {
  height: fit-content;
  /* min-width: 200px; */
  margin-top: 2%;
  /* margin-bottom: 4%; */
  /* margin-right: 2%; */
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  text-align: left;
  background-color: rgb(255, 255, 255);
  /* margin: 2%; */
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.locations-widget-wrapper {
  border-radius: 15px;
  padding-bottom: 20px;
}

.locations-wrapper {
  background-color: #26252537;
  border-radius: 15px;
  padding-bottom: 20px;
  margin-left: 4%;
  margin-right: 4%;
  /* width: 100%;
  min-height: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; */
}

.locations-wrapper-extended {
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.LocationTitle {
  margin-left: 5%;
  padding-top: 5%;
}

.LocationsTitle {
  text-align: left;
}
.LocationPhoneText {
  align-self: center;
  color: #42bca9;
}

.location-phone-text :hover {
  color: #359687 !important;
  background-color: #359687 !important;
}

card-section {
  padding-left: 15%;
}

.LocationAddressText {
  margin-left: 15px;
  /* align-self: left; */
  font-family: "Exo 2", sans-serif;
  font-size: medium;
}

.LocationCardIcon {
  height: 16px;
  width: 16px;
  margin-top: auto;
  margin-bottom: auto;
  margin: 10px;
}

.CardBottomSection {
  padding-right: 2%;
  padding-bottom: 4%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CardPhoneGroup {
  width: 100%;
  margin-left: 2%;
  text-align: left;
}

.location-card-target-group {
  cursor: pointer;
  color: #262525;
  width: fit-content;
  display: flex;
  width: 100%;
  padding-right: 10%;
  justify-content: right;
  align-items: center;
}

.TargetIcon {
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.MapWidgetTitle {
  padding-top: 10px;
  margin-left: 10px;
}

.locations-state-wrapper {
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.location-card-wrapper {
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  width: 90%;
  min-width: 200px;
  margin-top: 2%;
  margin-left: 2%;
  margin-bottom: 2%;
  margin-right: 2%;
  padding-left: 4%;
  margin-bottom: 5%;
  text-align: left;
  background-color: white;
  /* margin: 2%; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  padding-top: 5%;
}

.location-cards-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 2%;
}

.locations-state-title-wrapper {
  text-align: left;
  width: 90%;
  padding-left: 5%;
  margin-bottom: 2%;
  margin-top: 2%;
  /* margin: 2%; */
  /* margin-left: 5%; */
  padding-right: 15%;
  border-bottom: #262525 5px solid;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.book-a-session-wrapper {
  width: 100%;
  text-align: center;
  display: flex;
  padding-left: 5%;
  justify-content: left;
  align-items: center;
  margin-top: 5%;
  margin-bottom: auto;
  /* margin-left: auto; */
  padding-bottom: 5%;
}

.book-a-session {
  font-size: larger;
  /* padding-left: 5%; */
  text-decoration: none;
}


.book-a-session:hover {
  text-decoration: none;
}


.location-distance {
  width: 100%;
  text-align: right;
  padding-right: 10px;
}

.location-widget-closest-location {
  margin-left: 2%;
  width: 100%;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 5px;
}

.location-widget-find-more-group {
  display: flex;
  align-items: center;
  justify-content: right;
  text-decoration: none;
}

.sw-search-icon {
  height: 64px;
  width: 64px;
}

.CardSection {
  text-align: left;
  padding-top: 5%;
}

@media screen and (min-width: 1000px) {
  .HomeLocationRow {
    display: none;
  }
  .sw-search-icon {
    height: 64px;
    width: 64px;
  }
  .location-card-title {
    font-size: x-large;
  }
}

@media screen and (max-width: 1000px) {
  .home-location-widget-wrapper {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .home-location-widget-wrapper {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media screen and (max-width: 800px) {
  .sw-search-icon {
    height: 64px;
    width: 64px;
  }
}


@media screen and (max-width: 400px) {
  .sw-search-icon {
    height: 64px;
    width: 64px;
  }
}