#Ray1 {
  transform: translateY(35px) scale(2.7) rotate(90deg);
}

#Ray2 {
  transform: translateY(17px) scale(2.7) rotate(90deg);
}

#Ray3 {
  transform: translateY(10px) scale(2.7) rotate(90deg);
}

#Ray4 {
  transform: translateY(17px) scale(2.7) rotate(90deg);
}

#Ray5 {
  transform: translateY(39px) scale(2.7) rotate(90deg);
}

.RayAnimation {
  /* position: absolute; */
  /* z-index: 100; */
  top: 50%;
  left: 50%;
  width: 25px;
  enable-background: new 0 0 316 40;
  transform: rotate(90deg) translateY(-50%) scale(2.7);
  z-index: 10;
}

.ir-logo-ray-group {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 20px;
}


.ray-path {
  stroke-dasharray: 803px, 245px;
  stroke-dashoffset: 0;
  fill: none;
  /* stroke: hsla(19, 92%, 49%, 0.583); */
  z-index: 10;
  stroke: hsla(0, 92%, 49%, 0.516);
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-miterlimit: 5;
  animation: RayAnimation 1s linear infinite;
}

.sw-ray-rack-group {
  display: flex;
}

.sw-ray-rack-sub-group {
  margin-right: 3px;
}

.RayWrapper {
  position: relative;
  top: 25px;
  height: 15px;
}