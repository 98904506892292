.ei-header-title {
  color: white;
  text-align: center;
  padding-top: 2%;
  padding-bottom: 1%;
}
.ei-room-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.ei-content {
  color: white;
  width: 100%;
  min-height: 100vh;
  /* height: 100%; */
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}

.ei-container {
  background: -webkit-linear-gradient(
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.1) 50%,
    /* rgba(0, 0, 0, 0.7) 80%, */ /* rgba(0, 0, 0, 0.2) 90%, */
      /* rgba(0, 0, 0, 0.2) 95%, */ rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  min-height: 100%;
  background-color: #111413c6;
  background-color: #111413;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.bgColorBlack {
  background: -webkit-linear-gradient(
    rgba(140, 140, 140, 0.5) 0%,
    rgba(0, 0, 0, 0.05) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  height: 100%;
  width: 100%;
}

.employee-interface-title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  color: white;
  font-weight: 500;
  font-size: xx-large;
  margin-top: 5%;
  margin-bottom: 5%;
}


.ei-flex {
  display: flex;
}

.si-title-icon {
  height: 24px;
  width: 24px;
}