.SW-Card {
  /* min-height: fit-content; */
  /* min-width: fit-content; */
  border: 1px solid black;
  border-radius: 4px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  /* background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0.3) 100%,
    rgba(0, 0, 0, 0.7) 90%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.2) 95%,
    rgba(0, 0, 0, 0) 100%
  ); */
  /* width: min-content; */
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  height: min-content;
  min-width: 300px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
}

.SW-Card-hover:hover {
  transform: scale(1.05);
}

.SW-Card-Header {
  /* background: -webkit-linear-gradient(
    rgba(0, 0, 0, 0.3) 100%,
    rgba(0, 0, 0, 0.7) 90%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.2) 95%,
    rgba(0, 0, 0, 0) 100%
  ); */
  background-color: #262525;
  font-size: larger;
  width: 100%;
  font-weight: 500;
  color: rgb(0, 0, 0);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 3%;
}

.SW-Card-Body {
  width: 100%;
  font-weight: 500;
  padding-left: 5%;
  padding-right: 5%;
}


.SW-Card-Context {
  width: 100%;
}
