.App {
  text-align: center;
  margin: 0%;
  padding: 0%;
  height: 100%;
  width: 100%;
  transform: scale(1);
  /* overflow-x: hidden; */
}


button:focus,
button:active {
  outline: none !important; /* Use !important to override any inline styles or other CSS rules */
  border: none; /* Optionally remove any border */
  box-shadow: none; /* Optionally remove any box-shadow */
}