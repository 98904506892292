.mobile-nav-side-bar-wrapper {
  height: 0px;
  position: sticky;
  z-index: 100;
}

.mobile-nav-side-bar-group {
  width: auto;
  /* / height: 100%; */
  position: relative;
  left: 30%;
  top: 200;
  height: 100vh;
}
.mobile-nav-side-bar-wrapper {
  position: sticky;
  top: 70px;
}

.MobileNavSideBar {
  z-index: 99;
  padding-top: 2%;
  margin-right: 8%;
  top: 255px;
  /* width: 90%!important; */
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: 0.5s ease;
  height: 100vh;
  overflow: hidden;
}

.MobileNavSideBar-open {
  /* left: 150px; */
  height: 100vh;
  padding-left: 5%;
}

.MobileNavSideBar-closed {
  position: absolute;
  display: none;
  left: 200%;
  opacity: 0%;
  transition: 1s ease;
}

.MobileNavTopBar {
  height: 60px;
  background-color: transparent;
}

.SWMobileNavBarContainer-dropdown {
  width: 100%;
  top: -60px;
  position: sticky;
  align-content: center;
  justify-content: space-around;
  z-index: 100;
  box-shadow: 0 0 10px -4px rgb(0 0 0 / 25%);
  transition: 1s ease;
}

.SWMobileNavBarContainer-dropdown-active {
  width: 100%;
  top: 0px;
  position: sticky;
  align-content: center;
  justify-content: space-around;
  z-index: 100;
  box-shadow: 0 0 10px -4px rgb(0 0 0 / 25%);
  transition: 1s ease;
}

.sweatworks-logo-mobile {
  margin-top: 5px;
  margin-bottom: 5px;
  height: 50px;
}

.sweatworks-logo-mobile-nav {
  /* margin-top: 5px; */
  height: 50px;
}

.NavContent {
  height: 100%;
  width: 100%;
}

.mobile-nav-link {
  letter-spacing: 0.2rem;
  display: flex;
  justify-content: left;
  padding-top: 5%;
}

.mobile-nav-closer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-right: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.mobile-nav-closer-icon {
  height: 30px;
  /* margin-bottom: 5px; */
  width: auto;
}

.mobile-menu-icon {
  /* padding-top: 13px; */
  /* padding-right: 20px; */
  /* padding-left: 5%; */
  height: 36px;
  width: 36px;
}

.mobile-menu-icon-group {
  position: relative;
  height: 0px;
  display: flex;
  justify-content: flex-end;
}

.mobile-menu-icon-wrapper {
  margin-top: 8px;
  position: relative;
  left: -5%;
  padding-left: 10%;
  padding-bottom: 5px;
  height: 60px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-sweatworks-logo-wrapper {
  /* position: relative;
  height: 0px; */
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
}

.mobile-nav-top-row {
  display: flex;
  justify-content: space-between;
  width: 70%;
  align-content: center;
  margin-bottom: 10%;
  margin-top: 5%;
}
