html, body, #root {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  margin: 0px;
  padding: 0%;
}

html {
  overflow-y: hidden;
}