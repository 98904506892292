/* COLORS */

.sw-color-primary {
    color: #3abea8;
}

.sw-color-primary-light {
    color: #4ae9cf;
}

.sw-color-primary-dark {
    color: #277165;
}

.sw-color-primary-hover:hover {
    transition: 1s ease;
    color: #277165;
}

.sw-color-secondary {
    color: #ee6426;
}

.sw-color-secondary-light {
    color: #ff7e42;
}

.sw-color-secondary-dark {
    color: #893914;
}

.sw-secondary-hover:hover {
    color: #893914;
}

.sw-color-alternate-1 {
    color: #db1010;
}

.sw-color-alternate-1-light {
    color: #fd3232;
}

.sw-color-alternate-1-dark {
    color: #810000;
}

.sw-alternate-1-hover:hover {
    color: #810000;
}
