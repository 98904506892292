
.ei-room-data-plots-wrapper {
    background-color: rgba(17, 17, 17, 0.145);
    border: 1px solid black;
    border-radius: 5px;
    padding: 2%;
    margin-bottom: 1%;
    text-align: center;
}
.ei-room-data-plots-header {
    display: flex;
    justify-content: space-around;
    margin-top: 2%;
}

.ei-room-data-plots-header-title {
    margin-right: 10px;
}

.ei-room-data-update-button {
    max-width: 150px;
}

.ei-room-header-title {
    font-size: x-large;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-left: 5%;
    margin-top: 2%;
    margin-bottom: 10%;
    border: 2px solid gray;
    border-radius: 4px;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 2%;
    padding-right: 2%;
}
/* WEB  */

/* MOBILE  */
