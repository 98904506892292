.ei-header-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  min-height: 60px;
  justify-content: center;
  align-items: center;
}

.location-schedule-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  background-color: #4a4a4a63;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  border-radius: 10px;
  border: 1px solid black;
}

.location-exception-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  background-color: #4a4a4a63;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  border-radius: 10px;
  border: 1px solid black;
  padding-bottom: 50%;
}

.ei-new-exception-wrapper {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding-left: 5%;
  padding-right: 5%;
}

.ei-header-title {
  width: 100%;
}

.ei-schedule-title {
  margin-top: 5%;
  font-size: x-large;
  font-weight: 600;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: #ffffff;
  background: -webkit-linear-gradient(
    rgba(200, 200, 200, 0.5) 0%,
    rgba(0, 0, 0, 0.1) 50%,
    /* rgba(0, 0, 0, 0.7) 80%, */ /* rgba(0, 0, 0, 0.2) 90%, */
      /* rgba(0, 0, 0, 0.2) 95%, */ rgba(0, 0, 0, 0.3) 100%
  );
  border-radius: 6px;
}
.ei-schedule-subtitle {
  text-align: left;
  margin-top: 1%;
  margin-bottom: 1%;
  padding-left: 2%;
  font-size: medium;
  font-weight: 600;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: #0d2035;
  border-radius: 10px;
}

.ei-schedule-row-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  margin-bottom: 1%;
  background-color: #ffffff;
  border: 1px solid white;
  border-radius: 2px;
  padding-top: 1%;
  padding-bottom: 1%;
  background: -webkit-linear-gradient(
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.1) 50%,
    /* rgba(0, 0, 0, 0.7) 80%, */ /* rgba(0, 0, 0, 0.2) 90%, */
      /* rgba(0, 0, 0, 0.2) 95%, */ rgba(0, 0, 0, 0) 100%
  );
}

.ei-schedule-time-wrapper {
  display: flex;
  flex-wrap: nowrap;
}

.ei-schedule-day-title {
  width: 100px;
  text-align: left;
  font-size: large;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-top: 5px;
}

.ei-schedule-time-input {
  max-width: 100px;
  margin-right: 5%;
  border-radius: 5px;
  text-align: center;
}

.ei-exceptions-table {
  width: 100%;
  margin-bottom: 5%;
}

.ei-exceptions-tbody {
  width: 100%;
}

.ei-exceptions-tr {
  display: flex;
  justify-content: space-around;
  justify-items: center;
  text-align: center;
}

.ei-new-exception-content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding-top: 2%;
  padding-bottom: 4%;
}

.ei-locations-button {
  /* height: 30px; */
  min-width: 75px;
  max-width: 150px;
  margin-left: 5%;
}

.ei-locations-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 5%;
  padding-right: 5%;
}

.ei-exceptions-tr {
  min-width: 100px;
  border-radius: 2px;
  display: flex;
  justify-content: space-around;
}

.ei-exceptions-tr-header {
  background-color: black;
  width: 100%;
}

.ei-exceptions-inputs {
  margin-top: 2%;
}

.ei-exceptions-td {
  min-width: 50px;
}

.ei-exceptions-tr-body {
  color: white;
  display: flex;
  justify-content: space-around;
  background-color: #42bca9;
  margin-top: 2%;
  background: -webkit-linear-gradient(
    rgba(20, 20, 20, 0.5) 0%,
    rgba(0, 0, 0, 0.1) 50%,
    /* rgba(0, 0, 0, 0.7) 80%, */ /* rgba(0, 0, 0, 0.2) 90%, */
      /* rgba(0, 0, 0, 0.2) 95%, */ rgba(0, 0, 0, 0.3) 100%
  );
}

.ei-schedule-sub-title {
  margin-left: 43%;
  width: 150px;
  justify-content: space-between;
}

.ei-schedule-open-close-box {
  display: flex;
  justify-content: space-between;
  min-width: 175px;
}