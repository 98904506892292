.ei-back-button {
  transform: rotate(180deg);
  padding: 10%;
  max-height: 36px;
  max-width: 36px;
  color: #ed6426;
  border-radius: 50%;
  /* border: 3px solid #ffffff59; */
  border: 3px solid #ed6426;
}

.ei-back-button:hover {
  transform: translate(-5px, 0) rotate(180deg);
  transition: 3 sec ease;
  cursor: pointer;
}

.ei-back-button-wrapper {
  max-height: 36px;
  max-width: 36px;
  color: #ed6426;
}