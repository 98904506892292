.pricing-group {
  height: 1400px;
}

.PricingWrapper {
  min-height: 100vh;
  background: rgba(26, 212, 175, 0.904);
  background: linear-gradient(171deg, rgba(125, 236, 214, 0.904) 0%, #ff771c61 100%);
  background: -webkit-linear-gradient(
    171deg,
    rgba(26, 212, 175, 0.904) 0%,
    #ff751c 100%
  );
  background: -moz-linear-gradient(
    171deg,
    rgba(26, 212, 175, 0.904) 0%,
    #ff751c 100%
  );
}

.PricingContent {
  background: -webkit-linear-gradient(
    rgba(50, 221, 181, 0.231) 100%,
    rgba(0, 0, 0, 0.7) 90%,
    rgba(232, 115, 25, 0.2) 50%,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.2) 95%,
    rgba(0, 0, 0, 0) 100%
  );
  min-height: 100vh;
  /* background: -webkit-linear-gradient(
      rgba(122, 122, 122, 0.13) 0%,
      rgba(169, 169, 169, 0.9) 50%,
      rgba(49, 177, 154, 0) 100%
      ); */
  /* background-color: #000000; */
  color: black;
}

.CardTitle {
  justify-content: flex-start;
  margin-left: 10%;
}

.CardMoneyGroup {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: black;
}

.CardMoneySign {
  font-size: medium;
  align-self: flex-start;
}

.CardMoneyValue {
  font-size: xx-large;
}

.CardMoneyText {
  font-size: small;
}
