.navBarContainer {
  position: fixed;
  /* top: 50%; */
  /* left: 50%; */
  height: 60px;
  /* bring your own prefixes */
  width: 100%;
  /* transform: translate(-50%, 0%); */
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* overflow: hidden; */
  z-index: 100;
  box-shadow: 0 0 10px -4px rgb(0 0 0 / 25%);
  /* margin: auto; */
}

.iconWrapper {
  height: 50px;
  width: fit-content;
}

.access-nav-logo {
  height: 50px;
  width: auto;
}

.StatusIcon {
  color: #ce0b0b;
  height: px;
  width: auto;
}

.accessNavWrapper {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  padding-left: 5%;
  align-items: center;
}