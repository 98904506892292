.fab {
  text-decoration: none;
  color: white;
}
.FooterWrapper {
  width: 100%;
  max-height: min-content;
}

.Footer {
  /* background-color: black; */
  width: 100%;
  text-align: left;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  /* display: grid;
  grid-gap: 0px;
  grid-template-columns: repeat(auto-fit, 300px); */
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-top: 2%;
  max-height: 300px;
}

.sw-footer-col-group {
  margin-bottom: 30px;
  display: grid;
  grid-gap: 0px;
  grid-template-columns: repeat(auto-fit, 300px);
}

.Footer-top-margin {
  height: 1em;
  max-height: 50px;
}

.Footer-bottom-margin {
  height: 2em;
  max-height: 100px;
}

.FooterTitle {
  margin-bottom: 10%;
}

.FooterLink {
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
}
.FooterLink:hover {
  color: rgb(0, 70, 70);
  transition: 200ms ease-in;
}

.FooterIcon {
  cursor: pointer;
  height: 32px;
  width: 32px;
}

.LinkWrapper {
  margin-bottom: 10%;
}

.sweatworks-logo-footer {
  margin-bottom: 20px;
  height: 70px;
}

.sw-footer-col {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
}

.sw-footer-col-center {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-content: center;
  max-width: 300px;
}

@media screen and (min-width: 800px) {
  .sweatworks-logo-footer {
    height: 60px;
    margin-bottom: 20px;
  }
  .sw-footer-col-group {
    margin-bottom: 30px;
    grid-template-columns: repeat(auto-fit, 500px);
  }
}

@media screen and (max-width: 800px) {
  .sweatworks-logo-footer {
    height: 60px;
    margin-bottom: 20px;
  }
  .sw-footer-col-group {
    margin-bottom: 30px;
    grid-template-columns: repeat(auto-fit, 500px);
  }
}

@media screen and (max-width: 600px) {
  .sweatworks-logo-footer {
    height: 50px;
    margin-bottom: 20px;
  }
  .sw-footer-col-group {
    margin-bottom: 30px;
    grid-template-columns: repeat(auto-fit, 300px);
  }
}

@media screen and (max-width: 400px) {
  .sweatworks-logo-footer {
    height: 40px;
    margin-bottom: 20px;
  }
  .sw-footer-col-group {
    margin-bottom: 30px;
    grid-template-columns: repeat(auto-fit, 100vw);
  }
}
