.ReviewBackground {
  height: 500px;
}

.ReviewsGroup {
  /* vertical-align: middle; */
  width: 100%;
  /* height: max-content; */
  /* padding-bottom: 10%; */
  max-width: 1400px;
}

.Reviews {
  display: flex;
  position: relative;
  justify-content: center;
  align-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.carousel  {
  height: 100%;
}

.carousel-inner {
  height: 100%;
}

.carousel-indicators {
  position: relative;
  top: max-content;
}

.carousel-item-wrapper {
  height: 100%;
  width: 100%;
}

.ReviewHeaderGroup {
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 5%;
}

.ReviewsOverlay {
  position: relative;
}

.ReviewStarsWrapper {
  display: flex;
}

.Star {
  height: 32px;
  max-width: 32px;
  width: 32px;
  text-shadow: 2px 2px 0px rgba(49, 39, 26, 0.666);
}

.ReviewBGOuter {
  width: 100%;
  /* height: 100%; */
  background-color: rgba(46, 46, 46, 0.778);
  padding: 0.5%;
}

.ReviewBGInner {
  background-color: rgba(31, 31, 31, 0.57);
  padding: 2%;
}

.ReviewName {
  margin-top: 2%;
}
.ReviewDate {
  margin-top: 2%;
}

.ReviewContent {
  margin-top: 3%;
  margin-left: 5%;
  padding-bottom: 5%;
  margin-bottom: 5%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.what-our-clients-are-saying {
  position: relative;
  height: 0px;
  z-index: 10;
  top: 5%;
  margin-left: auto;
  margin-right: auto;
}

.carousel-item {
  width: 100%;
  margin-top: 15%;
  margin-bottom: 10%;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
  /* max-height: 500px; */
}

.ReviewWrapper {
  text-align: left;
}
.carousel-control-prev {
  background-color: transparent;
  border: 0px;
  z-index: 10;
  width: 10%;
}

.visually-hidden {
  display: none;
}

@media screen and (min-width: 1200px) {
  .ReviewWrapper {
    width: 100%;
    height: 100%;
  }
  .ReviewContent {
    font-size: larger;
  }
}

@media screen and (max-width: 1200px) {
  .ReviewWrapper {
    width: 100%;
    height: 100%;
  }
  .ReviewContent {
    font-size: large;
  }
}

@media screen and (max-width: 900px) {
  .ReviewWrapper {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
  }
  .ReviewContent {
    font-size: medium;
  }
}

@media screen and (max-width: 700px) {
  .ReviewWrapper {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 50px;
    padding-top: 20%;
    padding-bottom: 5%;
  }
  .ReviewContent {
    font-size: medium;
  }
}

@media screen and (max-width: 400px) {
  .ReviewWrapper {
    margin-top: 50px;
    padding-bottom: 5%;
  }
  .ReviewContent {
    font-size: small;
  }
}

@media screen and (min-width: 400px) {
  .ReviewWrapper {
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 50px;
    padding-bottom: 5%;
  }
}
