.BlogPageHome {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
}
.blog-page-home-header-bg {
  width: 100%;
  max-width: 1600px;
  min-height: 200px;
  background-size: cover;
  background-position: center;
  margin-left: auto;
  margin-right: auto;
}


.blog-page-sidebar-item {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 30px;
  margin-right: 30px;

  color: #090909;
  padding: 0.7em 1.7em;
  font-size: 18px;
  border-radius: 0.5em;
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  transition: all 0.3s;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}
.blog-page-sidebar-item:hover {
  border: 1px solid white;
}

.blog-page-sidebar-item:active {
  box-shadow: 4px 4px 12px #c5c5c5, -4px -4px 12px #ffffff;
}

.blog-page-home-header {
  padding-top: 100px;
  padding-left: 10px;
  padding-right: 10px;
}

.blog-page-home-content {
  margin-bottom: 50px;
  padding-left: 5%;
  padding-right: 5%;
}

.blog-page-home-content-group {
  display: flex;
  justify-content: space-around;
}

.blog-page-sidebar-subgroup {
  display: flex;
  justify-content: flex-end;
}

.blog-page-sidebar-title {
  text-align: left;
}

.blog-page-home-sidebar {
  width: 30%;
  max-width: 100px;
  background-size: cover;
  background-position: 90deg;
  height: 100%;
  margin-bottom: 50px;
  margin-left: 50px;
  min-height: 75vh;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  /* border-radius: 10px; */
  margin-top: 20px;
}

.blog-search-button {
  display: inline-block;
  outline: none;
  border-width: 0px;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: inherit;
  font-weight: 500;
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  transition: background 0.1s ease-out 0s,
    box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  background: #091e420a;
  cursor: pointer;
  height: 32px;
  line-height: 32px;
  padding: 0px 12px;
  margin: 2px 4px;
  vertical-align: middle;
  width: auto;
  font-size: 14px;
  color: #42526e;
}

.blog-search-button:hover {
  background: #091e4214;
  text-decoration: inherit;
  transition-duration: 0s, 0.15s;
  color: #42526e;
}
.blog-search-button-group {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

button {
  outline: none;
}
button::-moz-focus-inner {
  border: 0;
}

.blog-search-bar-group {
  width: 100%;
  /* margin-left: 5%; */
  /* margin-right: 5%; */
  margin-top: 5%;
  padding-bottom: 2%;
  margin-bottom: 2%;
  box-shadow: 0 0 3px 2px rgb(228 121 17 / 50%);
}

.blog-search-input-group {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5%;
  padding-bottom: 2%;
}
.blog-search-results-number {
  text-align: left;
  margin-left: 5%;
  width: 100%;
  margin-bottom: 2%;
}
.blog-search-input {
  background-color: #fff;
  height: 31px;
  padding: 3px 7px;
  line-height: normal;
  border: 1px solid #a6a6a6;
  border-top-color: #949494;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgb(255 255 255 / 50%), 0 1px 0 rgb(0 0 0 / 7%) inset;
  outline: 0;
  color: #111;
  font-size: 13px;
}
.blog-search-input:focus {
  border-color: #e77600;
  box-shadow: 0 0 3px 2px rgb(228 121 17 / 50%);
}

.blog-post-search-result-group {
  margin-bottom: 5%;
  padding: 5%;
  border: 1px solid #fff;
  border-radius: 15px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.blog-post-search-result-group:hover {
  transform: scale(1.02);
}

.blog-post-result-title {
  text-align: left;
}

.blog-post-snippet {
  text-align: left;
}

.blog-post-date {
  margin-top: 2%;
  text-align: right;
}

.blog-card-a {
  text-decoration: none;
}
.blog-card-a:hover {
  text-decoration: none;
}

.blog-post-recent-title {
  text-align: left;
  margin-bottom: 2%;
  margin-top: 10%;
  text-decoration: underline;
}

.blog-search-input-button-group {
  display: flex;
  align-items: center;
}

.blog-search-input-title {
  text-align: left;
  font-weight: bold;
  text-transform: capitalize;
  text-shadow: #090909;
  text-decoration: underline solid #2d2d2d ;
}

@media screen and (min-width: 1200px) {
  .blog-page-home-header {
    font-size: x-large;
  }
  .blog-page-home-sidebar {
    width: 20%;
  }
  .blog-page-sidebar-date {
    font-size: medium;
  }
}

@media screen and (max-width: 1200px) {
  .blog-page-home-header {
    font-size: x-large;
  }
  .blog-page-home-content-wrapper {
    width: 1000px;
  }
}

@media screen and (max-width: 900px) {
  .blog-page-home-header {
    font-size: larger;
  }
  .blog-page-sidebar-date {
    font-size: large;
  }
}

@media screen and (max-width: 700px) {
  .blog-page-home-sidebar {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .blog-page-home-header {
    font-size: large;
  }
  .blog-page-sidebar-date {
    font-size: medium;
  }
}

@media screen and (max-width: 400px) {
  .blog-page-home-header {
    font-size: medium;
  }
  .blog-page-sidebar-date {
    font-size: smaller;
  }
}