.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}

.pac-target-input {
  margin-top: 5px;
  margin-right: 5px;
}
